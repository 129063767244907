.eshoplistwrapper {
    margin-top: 45px;
    z-index: 10;
    position: relative;
}

.eshoplistheader {
    background: url('/eshop-list-bg.png') top right no-repeat;
    height: 225px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
}

@media only screen and (min-width: 768px) {
    .eshoplistwrapper {
        margin-top: 245px;
    }
    
    .eshoplistheader {
        height: 425px;
    }
}

